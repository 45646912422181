import './App.css';
import "bootstrap/dist/css/bootstrap.min.css"
import React from "react";
import {createBrowserRouter, Navigate, RouterProvider} from "react-router-dom";
import Accueil from "./components/accueil/Accueil";
import Projets from "./components/projets/Projets";
import Details from "./components/projets/details/Details";

const router = createBrowserRouter([
        {
            path: "/app", element: <App/>

        },
        {
            path: "/", element: <Accueil/>
        },
        {
            path: "/projets", element: <Projets/>
        },
        {
            path: "*",
            element: <Navigate to={"/"}/>
        },
        {
            path: "/projets/:id",
            element: <Details/>
        }

    ]
)


function App() {
    return (
        <div className="App">


            <RouterProvider router={router}/>

        </div>
    );
}

export default App;
