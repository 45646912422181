import React, {useEffect} from 'react';
import "./accueil.css"
import archiBg from "../../assets/IMG_4124.jpeg"
import interieur2 from "../../assets/slide2.png"
import loute1 from "../../assets/slide1.png"

import Navbar from "../navbar/Navbar";
import {useLocation} from "react-router-dom";
import Presentation from "./presentation/Presentation";
import Footer from "./footer/Footer";
import {Carousel} from "react-bootstrap";


const Accueil = () => {

    const location = useLocation();
    const {hash} = location

    useEffect(() => {

        function scrollToElement(event) {

            const element = document.getElementById(event.slice(1)); // Remplacez 'yourElementId' par l'ID de votre élément cible
            if (element) {
                element.scrollIntoView({behavior: 'smooth'}); // Défilement en douceur
            }
        }

        if (hash === "#about") {
            scrollToElement(hash)
        }
        if (hash === "#contact") {
            scrollToElement(hash)
        }
    }, [hash])

    return (

        <div>
            <div className={"accueil_banner"}>
                <Navbar props={"white"}/>
                <Carousel className={"vertical-carousel"} interval={3000}>
                    <Carousel.Item>
                        <div style={{
                            backgroundImage: `url(${loute1})`,
                            backgroundSize: "cover",
                            width: "100%",
                            height: "100vh",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"

                        }}>

                            <Carousel.Caption className={"carousel-caption"}>
                                <div className={"accueil_content"}>
                                    <div className={"accueil_content_name"}><h1>Lauriane Korczak</h1>
                                        <h5>Architecte d'intérieur & designer</h5>
                                    </div>
                                </div>
                            </Carousel.Caption>
                        </div>

                    </Carousel.Item>
                    <Carousel.Item>
                        <div style={{
                            backgroundImage: `url(${archiBg})`,
                            backgroundSize: "cover",
                            backgroundPosition:"center 75%",
                            width: "100%",
                            height: "100vh",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"

                        }}>

                            <Carousel.Caption className={"carousel-caption"}>
                                <div className={"accueil_content"}>
                                    <div className={"accueil_content_name"}>
                                        <h1>Architecte d'intérieur & designer</h1>
                                    </div>

                                </div>
                            </Carousel.Caption>
                        </div>

                    </Carousel.Item>
                    <Carousel.Item>
                        <div style={{
                            backgroundImage: `url(${interieur2})`,
                            backgroundSize: "cover",
                            backgroundPosition:"bottom",
                            width: "100%",
                            height: "100vh",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>

                            <Carousel.Caption className={"carousel-caption"}>

                                <div className={"accueil_content"}>
                                    <div className={"accueil_content_name"}>
                                        <h1>Maisons et appartements</h1>
                                    </div>

                                </div>

                            </Carousel.Caption>
                        </div>

                    </Carousel.Item>
                </Carousel>


            </div>
            <Presentation/>

            <Footer/>
        </div>


    )
        ;
};

export default Accueil;