import React, {useState} from 'react';
import {Link} from "react-router-dom";
import "./navbar.css"
import Logo from "../../assets/Logo.png"
import {MdClose, MdDehaze} from "react-icons/md";
import Footer from "../accueil/footer/Footer";
import {FaInstagram, FaLinkedin} from "react-icons/fa";


const Navbar = ({props, bg}) => {

    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className={"navbar_perso"} style={{color: `${props}`, backgroundColor: `${bg}`}}>
            <div>
                <Link style={{color: `${props}`}} to={"/"}><img src={Logo} className={"navbar_logo"}
                                                                alt={"logo Lauriane Korczak"}/></Link>

            </div>

            <div className={`overlay ${isOpen ? "active" : ""}`} onClick={() => setIsOpen(!isOpen)}></div>
            <div className={"navbar_list"}>
                <ul>
                    <li>
                        <Link to={"/projets"} style={{color: `${props}`, fontWeight: "bold"}}>Réalisations</Link>
                    </li>
                    <li>
                        <Link to="/#about" style={{color: `${props}`, fontWeight: "bold"}}>A propos</Link>
                    </li>
                    <li>
                        <Link to={"/#contact"} style={{color: `${props}`, fontWeight: "bold"}}>Contact</Link>
                    </li>

                </ul>
            </div>
            <div className={"navbar_burger"}>


                {
                    isOpen ? (
                        <div className={`navbar_burger_show ${isOpen ? "active" : ""}`}>
                            <MdClose color={"white"} onClick={() => setIsOpen(!isOpen)}/>
                            <div className={"navbar_burger_show_content"}>
                                <ul>
                                    <li>
                                        <Link onClick={() => setIsOpen(!isOpen)} to={"/projets"}
                                              style={{color: `${props}`, fontWeight: "bold"}}>Réalisations</Link>
                                    </li>
                                    <li>
                                        <Link onClick={() => setIsOpen(!isOpen)} to="/#about" style={{color: `${props}`, fontWeight: "bold"}}>A
                                            propos</Link>
                                    </li>
                                    <li>
                                        <Link onClick={() => setIsOpen(!isOpen)} to={"/#contact"}
                                              style={{color: `${props}`, fontWeight: "bold"}}>Contact</Link>
                                    </li>

                                </ul>

                                <div className={"navbar_burger_footer"}>
                                    <div className={"navbar_burger_rs_content"}>
                                        <FaLinkedin/>

                                        <FaInstagram/>
                                    </div>


                                </div>
                            </div>
                        </div>
                    ) : <MdDehaze color={"white"} onClick={() => setIsOpen(!isOpen)}/>
                }

            </div>
        </div>);
};

export default Navbar;