import React from 'react';
import Lauriane from "../../../assets/LaurianeBG.png"
import "./presentation.css"

const Presentation = () => {
    return (
        <div className={"presentation"} id={"about"}>
            <div className={"presentation_bio"}>
                <h1>Korczak Lauriane</h1>
                <p>
                    Architecte d'intérieur créative et dévouée actuellement étudiante en troisième année à l’école MJM
                    Graphic Design de Bordeaux.
                    <br/>
                    <br/>
                    <br/>

                    Mon approche repose sur la compréhension profonde des besoins et des désirs de mes clients, afin de
                    créer des espaces uniques et fonctionnels qui racontent une histoire. Mon expertise va de la
                    conception de résidences privées à l'aménagement d'espaces commerciaux dynamiques.
                    <br/>
                    <br/>
                    <br/>

                    Je suis toujours à la recherche de nouveaux défis et opportunités pour créer des espaces inspirants.
                    N'hésitez pas à me contacter pour discuter de vos projets futurs.

                </p>
            </div>
            <div className={"presentation_photo"}>
                <img src={Lauriane} className={"presentation_photo_img"} alt={"Lauriane Korczak"}/>
            </div>
        </div>
    );
};

export default Presentation;