import React, {useState} from 'react';
import projet1 from "../../../assets/slide1.png"
import projet2_2 from "../../../assets/projet hippo/T1_EX1_PLAN MASSE_1-200 Lauriane Korczak.png"
import projet2_1 from "../../../assets/slide2.png"
import projet3 from "../../../assets/IMG_4124.jpeg"
import {useParams} from "react-router-dom";
import Navbar from "../../navbar/Navbar";
import "./details.css"
import Footer from "../../accueil/footer/Footer";


const Details = () => {

    const path = useParams();

    const [isModalOpen, setIsModalOpen] = useState(null);

    const handleImageClick = (image) => {
        setIsModalOpen(image);
    };

    const handleCloseModal = () => {
        setIsModalOpen(null);
    };


    const allProjects = [
        {
            title: "Séjour d'inspiration Japandi",
            description: "Cet aménagement est une réalisation élégante mêlant les influences japonaises et scandinaves, souvent désignée sous le terme Japandi. Il propose un espace de vie à la fois minimaliste et chaleureux, où le bois et la pierre se complètent harmonieusement.\n" +
                "\n" +
                "Le design minimaliste se manifeste par des lignes épurées et des espaces dégagés. Le mobilier bas, typique du style japonais, invite à la tranquillité et à la sérénité, tandis que le bois clair utilisé pour le sol et le mobilier apporte une chaleur naturelle, reflétant l'influence scandinave.\n" +
                "\n" +
                "Le mur en pierre brute, qui s'étend sur toute la hauteur du salon, crée un contraste saisissant avec la douceur du bois et accentue la sensation d'espace et de grandeur. Les grandes baies vitrées permettent à la lumière naturelle d'inonder le séjour, tout en offrant une vue imprenable sur un jardin avec des cerisiers en fleur, créant une transition fluide entre l'intérieur et l'extérieur.\n" +
                "\n" +
                "La décoration est sobre et raffinée, avec des éléments soigneusement choisis tels que des plantes vertes, des objets en céramique et un mobilier bas. Ces éléments ajoutent des touches de couleur discrètes et renforcent l'atmosphère apaisante du lieu.",
            picture: [projet1]
        },
        {
            title: "Ambiance Paysagère autour d'une Piscine sur le Bassin d'Arcachon",
            description: "Ce projet d'aménagement paysager autour d'une piscine sur le Bassin d'Arcachon représente une fusion parfaite entre la nature et le confort moderne. Grâce à une conception réfléchie et à l'utilisation de matériaux et de végétaux locaux, nous avons créé un espace qui non seulement enrichit l'expérience de vie des résidents, mais contribue également à la préservation de l'environnement naturel unique de la région.",
            picture: [projet2_1, projet2_2]
        },
        {
            title: "L'Éveil de Nezuko",
            description: "Cette maquette représente une scène clé de la transformation de Nezuko, personnage emblématique de l'univers de \"Demon Slayer\". La scène se déroule dans le quartier des plaisirs, également connu sous le nom de Yoshiwara, célèbre district de divertissement d'Edo, l'ancien Tokyo.\n" +
                "\n" +
                "Le bâtiment principal, éclairé de l'intérieur, reflète l'architecture traditionnelle japonaise avec ses portes coulissantes en papier washi et ses lanternes rouges suspendues qui illuminent la scène d'une lueur mystique. Les détails architecturaux et les lumières soigneusement positionnées créent une ambiance immersive, rappelant les nuits animées et mystérieuses de Yoshiwara.\n" +
                "\n" +
                "À l'avant-plan, on aperçoit la silhouette de Nezuko en pleine transformation, symbolisant sa lutte intérieure et sa puissance croissante. Les éléments du décor, comme les plantes soigneusement disposées, ajoutent une touche de réalisme et complètent l'ambiance traditionnelle de l'époque d'Edo.",
            picture: [projet3]
        }
    ]
    return (
        <>

            <div className={"details"}>
                <Navbar props={"white"} bg={"#333333"}/>

                <div className={"details_content"}>
                    <div className={"details_content_text"}>
                        <h1>{allProjects[path.id].title}</h1>
                        <p style={{ whiteSpace: 'pre-line' }}>
                            {allProjects[path.id].description}
                        </p>

                    </div>
                    <div className={"details_content_picture"}>
                        {
                            allProjects[path.id].picture.map((index) => (
                                <div className={"containerPerso"}>
                                    <img src={index} onClick={() => handleImageClick(index)}
                                         alt={`${allProjects[path.id].title}`}/>
                                    {isModalOpen && (
                                        <div className="modalPerso" onClick={handleCloseModal}>
                                            <div className="modalPerso-content">
                                                <img src={isModalOpen} alt="Zoomable" className="zoomed"/>
                                            </div>
                                        </div>
                                    )}

                                </div>
                            ))
                        }
                    </div>


                </div>
                <Footer/>
            </div>
        </>
    );
};

export default Details;